import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { USER_ID, TEAM_NAME, ORG_ID } from '../constants';
import { useWidgetState } from '../state/widgetState';
import { getHeader } from '../utils/getHeader';

const DfMessenger = (): JSX.Element => {
  const { isOpen } = useWidgetState();
  const { search } = useLocation();
  const {
    i18n: { language },
  } = useTranslation();
  const {
    REACT_APP_DF_MESSENGER_LOCATION,
    REACT_APP_DF_MESSENGER_AGENT_ID,
    REACT_APP_USER_ID,
    REACT_APP_DF_MESSENGER_INTENT,
    REACT_APP_TEAM_NAME,
    REACT_APP_ORGANISATION_ID,
  } = process.env;

  const location = getHeader(search, 'location') || REACT_APP_DF_MESSENGER_LOCATION;
  const agentId = getHeader(search, 'agent-id') || REACT_APP_DF_MESSENGER_AGENT_ID;
  const teamName = sessionStorage.getItem(TEAM_NAME) || REACT_APP_TEAM_NAME;
  const organisationId = sessionStorage.getItem(ORG_ID) || REACT_APP_ORGANISATION_ID;
  const userId = sessionStorage.getItem(USER_ID) || REACT_APP_USER_ID;
  const userIdOjbect = JSON.stringify({
    external_user_uuid: userId,
    team_name: teamName,
    organisation_id: organisationId,
  });
  const messengerIntent = getHeader(search, 'messenger-intent') || REACT_APP_DF_MESSENGER_INTENT;

  return (
    <>
      <df-messenger
        df-cx="true"
        location={location}
        chat-title="Wellbeing"
        agent-id={agentId}
        user-id={userIdOjbect}
        language-code={language.slice(0, 2)}
        expand={isOpen}
        style={style}
        intent={messengerIntent}
      />
    </>
  );
};

type MessengerStyleType = {
  '--df-messenger-scroll-bar-color'?: string;
  '--df-messenger-scroll-bar-border-radius'?: string;
  '--df-messenger-scroll-bar-border-width'?: string;
  '--df-messenger-chip-color'?: string;
  '--df-messenger-chip-width'?: string;
  '--df-messenger-title-card-background-color'?: string;
  '--df-messenger-chip-border'?: string;
  '--df-messenger-chip-border-radius'?: string;
  '--df-messenger-response-hover-background'?: string;
  '--df-messenger-card-wrapper-background-color'?: string;
  '--df-messenger-chat-background-image'?: string;
  '--df-messenger-card-wrapper-border'?: string;
  '--df-messenger-card-wrapper-border-radius'?: string;
  '--df-messenger-user-input-display'?: string;
  '--df-messenger-display-widget-button'?: string;
  '--df-messenger-bot-message'?: string;
  '--df-messenger-button-titlebar-color'?: string;
  '--df-messenger-button-titlebar-font-color'?: string;
  '--df-messenger-chat-background-color'?: string;
  '--df-messenger-input-box-color'?: string;
  '--df-messenger-font-color'?: string;
  '--df-messenger-input-placeholder-font-color'?: string;
  '--df-messenger-minimized-chat-close-icon-color'?: string;
  '--df-messenger-send-icon'?: string;
  '--df-messenger-user-message'?: string;
  '--df-messenger-user-message-opacity'?: string;
  '--df-messenger-font-family'?: string;
  '--df-messenger-font-size'?: string;
  '--df-messenger-position-bottom'?: string;
};
//use style to change the messenger default style
const style: MessengerStyleType = {};

export default DfMessenger;
